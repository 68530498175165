<template>
    <div v-if="contents.length>0" class="card kultour-story mt30 mb30">
        <span class="title">Geschichten aus den Kulturspuren</span>

        <story-part v-for="content in contents" :content="content" class="part"/>

    </div>
</template>

<script>
 import Resource from '@/api/resource';
 import { getFieldValues, showLoader, hideLoader } from '@/utils/helpers';

 const contentResource = new Resource('contents');

 export default {
    name: "Story",
    components: {
        StoryPart: () =>import('./storyPart.vue'),
    },
    props: {
        contentIds: {
            type: Array,
        }
    },
    data(){
        return{
            loader: null,
            contents: [],
  }
},
computed: {

},
created() {
    this.getContent();
},
methods: {
    showLoader,
    hideLoader,
    getContent() {
        for(var i=0; i<this.contentIds.length;i++){
            contentResource.get(this.contentIds[i])
            .then(response => {
              this.contents.push(response.data);
          })
            .finally(() => {
          });
           } 
        }
    },
}
</script>

<style lang="scss" scoped>

    .title{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'elliot-bold', sans-serif;
    }

    .part{
        margin-bottom: 20px;
    }

</style>
